/**
 * @generated SignedSource<<f7031f923a8a632cc164eaf8f2adfa34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useDropState_data$data = {
  readonly dropv2: {
    readonly mintedItemCount: number;
    readonly stages: ReadonlyArray<{
      readonly endTime: string;
      readonly startTime: string;
    }>;
    readonly totalItemCount?: number;
  } | null;
  readonly statsV2: {
    readonly totalSupply: number;
  };
  readonly " $fragmentType": "useDropState_data";
};
export type useDropState_data$key = {
  readonly " $data"?: useDropState_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDropState_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalItemCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useDropState_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSupply",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mintedItemCount",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Drop721LimitedEditionType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Drop1155LimitedEditionType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "2224456b4c6ecc5f5ecd7b66af99e668";

export default node;
