import { useCallback, useEffect, useState } from "react"
import {
  add,
  sub,
  isAfter,
  isBefore,
  parseJSON,
  differenceInMilliseconds,
} from "date-fns"
import { isNil } from "lodash"
import { useInterval } from "react-use"
import { graphql } from "relay-runtime"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { usePolledDropDataProgressQuery } from "@/lib/graphql/__generated__/usePolledDropDataProgressQuery.graphql"
import { usePolledDropDataQuery } from "@/lib/graphql/__generated__/usePolledDropDataQuery.graphql"
import { isPastStage, isStageActive } from "../containers/PrimaryDropProvider"
import { useDropStages } from "./useDropStages"

const REQUEST_INTERVAL_MS = 750
const MINT_PROGRESS_REQUEST_INTERVAL_MS = 12 * 1000

const BEGIN_POLLING_BUFFER_SECONDS = REQUEST_INTERVAL_MS / 1000 + 2

const END_POLLING_BUFFER_SECONDS = 5

export const usePolledDropData = ({
  collectionSlug,
  editMode,
  is1155,
}: {
  collectionSlug: string
  editMode?: boolean
  is1155: boolean
}) => {
  const [stageData, refetchStageData] =
    useNoSuspenseLazyLoadQuery<usePolledDropDataQuery>(
      graphql`
        query usePolledDropDataQuery($collection: CollectionSlug) {
          collection(collection: $collection) {
            dropv2 {
              stages {
                ...useDropStages
                label
                relayId
                startTime
                endTime
                isEligible
                ... on DropStage721PresaleLinearPricingType {
                  availableMintsForUser
                  perWalletLimit
                }
                ... on DropStage721PublicLinearPricingType {
                  availableMintsForUser
                  perWalletLimit
                }
                stageType
              }
            }
          }
        }
      `,
      { collection: collectionSlug },
      { skip: !collectionSlug || editMode },
    )
  const [_, refetchProgressData] =
    useNoSuspenseLazyLoadQuery<usePolledDropDataProgressQuery>(
      graphql`
        query usePolledDropDataProgressQuery($collection: CollectionSlug) {
          collection(collection: $collection) {
            dropv2 {
              # We're ignoring these because we're just fetching them to update
              # them in the store, not to use them here
              ... on Drop1155LimitedEditionType {
                # eslint-disable-next-line relay/unused-fields
                mintedItemCount
              }
              ... on Drop1155OpenEditionType {
                # eslint-disable-next-line relay/unused-fields
                mintedItemCount
              }
              stages {
                ... on DropStage1155PublicLinearPricingType {
                  # eslint-disable-next-line relay/unused-fields
                  mintOptions {
                    mintedItemCount
                  }
                }
                ... on DropStage1155PresaleLinearPricingType {
                  # eslint-disable-next-line relay/unused-fields
                  mintOptions {
                    mintedItemCount
                  }
                }
              }
            }
          }
        }
      `,
      { collection: collectionSlug },
      { skip: !collectionSlug || editMode || !is1155 },
    )

  const { ctaStage, currentStage } = useDropStages(
    stageData?.collection?.dropv2?.stages ?? [],
  )
  const stageEnd = ctaStage?.endTime ? parseJSON(ctaStage.endTime) : null

  useInterval(() => {
    const now = Date.now()

    // poll for updated stage data if we're just about to end the current stage - this is the only situation where ctaStage will change
    // allow a buffer after the start/end times where we keep polling to ensure we're getting the most up-to-date response
    const shouldFetch =
      stageEnd &&
      isAfter(now, sub(stageEnd, { seconds: BEGIN_POLLING_BUFFER_SECONDS })) &&
      isBefore(now, add(stageEnd, { seconds: END_POLLING_BUFFER_SECONDS }))

    if (shouldFetch) {
      refetchStageData({ collection: collectionSlug }, { force: true })
    }
  }, REQUEST_INTERVAL_MS)

  useInterval(() => {
    const shouldFetch = !isNil(currentStage)

    if (shouldFetch) {
      refetchProgressData({ collection: collectionSlug })
    }
  }, MINT_PROGRESS_REQUEST_INTERVAL_MS)

  const [ctaStageIsActive, setCtaStageIsActive] = useState(false)
  const [ctaStageIsPast, setCtaStageIsPast] = useState(false)

  const setCtaStageStatus = useCallback(() => {
    if (ctaStage?.relayId) {
      const isActive = isStageActive({
        startTime: ctaStage.startTime,
        endTime: ctaStage.endTime,
      })
      const isPast = isPastStage({ endTime: ctaStage.endTime })
      setCtaStageIsActive(isActive)
      setCtaStageIsPast(isPast)

      return {
        isActive,
        isPast,
      }
    }

    return {
      isActive: false,
      isPast: false,
    }
  }, [ctaStage])

  useEffect(() => {
    setCtaStageStatus()
    let stageStartTimeout: NodeJS.Timeout | null = null
    let stageEndTimeout: NodeJS.Timeout | null = null

    if (!ctaStageIsPast && ctaStage) {
      if (!ctaStageIsActive) {
        // setTimeout to re-render when ctaStage starts
        const startTime = parseJSON(ctaStage.startTime)
        const timeoutDuration = differenceInMilliseconds(startTime, Date.now())

        stageStartTimeout = setTimeout(() => {
          setCtaStageStatus()
        }, timeoutDuration)
      }
      if (ctaStage.endTime && ctaStage.stageType === "PUBLIC") {
        const endTime = parseJSON(ctaStage.endTime)
        const timeoutDuration = differenceInMilliseconds(endTime, Date.now())

        // set timeout to re-render when ctaStage ends
        stageEndTimeout = setTimeout(() => {
          setCtaStageStatus()
        }, timeoutDuration)
      }
    }

    return () => {
      if (stageStartTimeout) {
        clearTimeout(stageStartTimeout)
      }
      if (stageEndTimeout) {
        clearTimeout(stageEndTimeout)
      }
    }
  }, [ctaStage, ctaStageIsActive, ctaStageIsPast, setCtaStageStatus])

  return {
    isStageActive,
    isPastStage,
    ctaStageIsActive,
    ctaStageIsPast,
    refetchCtaStageState: refetchStageData,
    availableMints: ctaStage?.availableMintsForUser ?? 0,
    ...(ctaStage
      ? {
          label: ctaStage.label,
          perWalletLimit: ctaStage.perWalletLimit,
          stageType: ctaStage.stageType,
          startTime: ctaStage.startTime,
          endTime: ctaStage.endTime,
          isEligible: ctaStage.isEligible,
          availableMintsForUser: ctaStage.availableMintsForUser,
        }
      : {}),
  }
}
