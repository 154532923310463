import React, { useEffect, useState } from "react"
import { Flex, FlexProps, Media, classNames } from "@opensea/ui-kit"
import { useInView } from "react-intersection-observer"
import styled, { css } from "styled-components"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { useMountEffect } from "@/hooks/useMountEffect"
import { getScrollbarWidth } from "@/lib/helpers/scrollbar"
import { media } from "@/styles/styleUtils"
import THEMES from "@/styles/themes"

export type StickyBarProps = {
  shadowVariant: "persistentBorder" | "border" | "shadow" | "none"
  fullWidth?: boolean
  height: number
  elementId?: string
  topOffsetOverride?: number
} & FlexProps

const useBoxShadowValue = ({
  shadowVariant,
  inView,
}: Pick<StickyBarProps, "shadowVariant"> & {
  inView: boolean
}) => {
  const { theme } = useTheme()

  if (shadowVariant === "border") {
    return {
      _: "none",
      xs: !inView
        ? theme === "light"
          ? THEMES.light.shadows.borderBottom
          : THEMES.dark.shadows.borderBottom
        : "",
    }
  }

  if (shadowVariant === "shadow") {
    return {
      _: "none",
      xs: !inView
        ? theme === "light"
          ? THEMES.light.shadows.button
          : THEMES.dark.shadows.button
        : "",
    }
  }

  if (shadowVariant === "persistentBorder") {
    return {
      _:
        theme === "light"
          ? THEMES.light.shadows.borderBottom
          : THEMES.dark.shadows.borderBottom,
    }
  }

  return {}
}

export function StickyBar({
  children,
  className,
  elementId,
  height,
  fullWidth = true,
  topOffsetOverride,
  shadowVariant,
  style,
  ...rest
}: StickyBarProps) {
  const { setHasStickyElement, filterTopOffset } =
    useAccountOrCollectionPageContext()
  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  const topOffset = topOffsetOverride ?? filterTopOffset

  const { ref, inView } = useInView({
    initialInView: true,
    // This adds in some margin where the actual intersection observer would start triggering
    rootMargin: `-${(topOffset || 0) + 4}px 0px ${(topOffset || 0) + 4}px 0px`,
    threshold: [0.6],
  })

  useEffect(() => {
    setHasStickyElement(!inView)
  }, [inView, setHasStickyElement])

  useMountEffect(() => {
    setScrollbarWidth(getScrollbarWidth())
  })

  const widthOffset = scrollbarWidth

  const boxShadow = useBoxShadowValue({
    shadowVariant,
    inView,
  })

  return (
    <>
      <span id={elementId} ref={ref} />
      <Flex
        {...rest}
        className={classNames("xs:sticky", className)}
        style={{
          top: topOffset - 2,
          zIndex: Z_INDEX.PHOENIX_HEADER,
          height,
          ...style,
        }}
      >
        <InnerContainer
          boxShadow={boxShadow}
          className="top-0 bg-base-1"
          fullWidth={fullWidth}
          height="100%"
          inView={inView}
          width={`calc(100vw - ${widthOffset}px)`}
          widthOffset={widthOffset}
        >
          {children}
        </InnerContainer>
      </Flex>
      <Media lessThan="xs">
        <Block
          className="relative"
          style={{ top: `-${topOffset - height}px` }}
        />
      </Media>
    </>
  )
}

const InnerContainer = styled(Block)<{
  inView: boolean
  widthOffset: number
  fullWidth: boolean
}>`
  display: flex;
  align-items: center;

  ${props =>
    media({
      xs: css`
        position: absolute;

        ${props.fullWidth
          ? css`
              left: calc((100vw - (100% + ${props.widthOffset}px)) / -2);
              right: calc((100vw - (100% + ${props.widthOffset}px)) / -2);
            `
          : css`
              margin-left: calc(
                -1 * ((100vw - (100% + ${props.widthOffset}px)) / 2)
              );
              margin-right: calc(
                -1 * ((100vw - (100% + ${props.widthOffset}px)) / 2)
              );
              padding-left: calc((100vw - (100% + ${props.widthOffset}px)) / 2);
              padding-right: calc(
                (100vw - (100% + ${props.widthOffset}px)) / 2
              );
            `}
      `,
    })}
`
