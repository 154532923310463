/**
 * @generated SignedSource<<a9a9b81b65c9b558f1d7b7939a2012c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dropTypeTests_stages$data = {
  readonly stages: ReadonlyArray<{
    readonly mintOptions?: ReadonlyArray<{
      readonly __typename: string;
    }>;
  }>;
  readonly " $fragmentType": "dropTypeTests_stages";
};
export type dropTypeTests_stages$key = {
  readonly " $data"?: dropTypeTests_stages$data;
  readonly " $fragmentSpreads": FragmentRefs<"dropTypeTests_stages">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "dropTypeTests_stages"
};

(node as any).hash = "bd3f7c4d091676a806231b88171d2d77";

export default node;
