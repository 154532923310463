import { useMemo } from "react"
import { graphql, useSubscription } from "react-relay"
import { GraphQLSubscriptionConfig } from "relay-runtime"
import { usePrimaryDropPageStatsEventSubscription } from "@/lib/graphql/__generated__/usePrimaryDropPageStatsEventSubscription.graphql"

export const usePrimaryDropPageStatsEvent = ({
  relayId,
  slug,
}: {
  // passing relayId here to use in `updater`, since we don't have access to the collection in the subscribed event
  relayId: string
  slug: string
}) => {
  const config: GraphQLSubscriptionConfig<usePrimaryDropPageStatsEventSubscription> =
    useMemo(
      () => ({
        variables: { slug },
        subscription: graphql`
          subscription usePrimaryDropPageStatsEventSubscription(
            $slug: String!
          ) {
            statsEvent(slug: $slug) {
              collection {
                statsV2 {
                  hasFungibles
                  totalQuantity
                  totalSupply
                }
              }
            }
          }
        `,
        updater: (store, data) => {
          const {
            statsEvent: {
              collection: {
                statsV2: { hasFungibles, totalQuantity, totalSupply },
              },
            },
          } = data
          const proxy = store.get(relayId)
          if (!proxy) {
            return
          }
          const record = proxy.getOrCreateLinkedRecord(
            "statsV2",
            "CollectionStatsV2Type",
          )
          // `hasFungibles` is derived from the other two values.
          // We want to rely on the server to guarantee atomicity here,
          // so always update these three fields together. We should also
          // avoid comparing these values for logic on the frontend.
          // TODO: Move this update into a declarative subscription pattern.
          record.setValue(hasFungibles, "hasFungibles")
          record.setValue(totalQuantity, "totalQuantity")
          record.setValue(totalSupply, "totalSupply")
        },
      }),
      [relayId, slug],
    )

  useSubscription<usePrimaryDropPageStatsEventSubscription>(config)
}
