/* eslint-disable tailwindcss/no-custom-classname */
import React, { ReactNode, useRef, useState, useMemo, useEffect } from "react"
import {
  Media,
  UnstyledButton,
  Text,
  classNames,
  useIsLessThanXXl,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { ClassValue } from "clsx"
import { rgba } from "polished"
import { useInView } from "react-intersection-observer"
import { HeadingComponent } from "react-markdown/lib/ast-to-react"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { Block } from "@/design-system/Block"
import { Markdown } from "@/design-system/Markdown"
import { NumberTicker } from "@/design-system/NumberTicker"
import { Tooltip } from "@/design-system/Tooltip"
import { useIsMerchandisingViewEnabled } from "@/hooks/useFlag"
import { useLayoutEffect } from "@/hooks/useIsomorphicLayoutEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { themeVariant } from "@/styles/styleUtils"
import { NAV_HEIGHT_PX } from "@/styles/variables"

type DetailItem = {
  prevNumber?: string | null
  number?: string
  label: string
  symbol?: string | null
  url?: string
  testId?: string
  tooltip?: string
  onClick?: () => void
  variant?: "inline" | "default"
}

export type AccountOrCollectionInfoProps = {
  text?: string | JSX.Element
  details?: Array<DetailItem>
  mobileLines?: number
  desktopLines?: number
  labelClassName?: ClassValue
  numberClassName?: ClassValue
}

export const AccountOrCollectionInfo = ({
  text,
  details,
  mobileLines,
  desktopLines,
  labelClassName,
  numberClassName,
}: AccountOrCollectionInfoProps) => {
  const { setScrolledPastStats } = useAccountOrCollectionPageContext()
  const mainText = useMemo(() => {
    if (!text) {
      return null
    }
    return (
      <MainText
        desktopLines={desktopLines}
        mobileLines={mobileLines}
        text={text}
      />
    )
  }, [text, desktopLines, mobileLines])

  const { ref, inView } = useInView({
    initialInView: true,
    rootMargin: `-${NAV_HEIGHT_PX + 4}px 0px ${NAV_HEIGHT_PX + 4}px 0px`,
    threshold: [0.6],
  })

  useEffect(() => {
    setScrolledPastStats(!inView)
  }, [inView, setScrolledPastStats])

  return (
    <Block>
      {mainText}
      <Details
        details={details}
        labelClassName={labelClassName}
        numberClassName={numberClassName}
      />
      <span ref={ref} />
    </Block>
  )
}

function getEls<T>(arr: Array<T>, max: number, col: number): Array<T> {
  return arr
    .map((el, idx) => {
      if (idx % max === col) {
        return el
      }
      return null
    })
    .filter((val): val is T => Boolean(val))
}

const StatsButton = styled(UnstyledButton)`
  text-align: left;
`

function WrappedLink({
  url,
  label,
  children,
  testId,
  onClick,
}: {
  label: string
  url?: string
  children: ReactNode
  testId?: string
  onClick?: () => void
}) {
  if (onClick) {
    return (
      <StatsButton data-testid={testId} onClick={onClick}>
        {children}
      </StatsButton>
    )
  }

  if (url) {
    return (
      <Link data-testid={testId} eventSource={label} href={url}>
        {children}
      </Link>
    )
  }

  return <span data-testid={testId}>{children}</span>
}

type NumberContentProps = {
  textSize: "large" | "small"
  number?: string
  prevNumber?: string | null
  symbol?: string | null
}

const NumberContent = ({
  textSize,
  number,
  prevNumber,
  symbol,
}: NumberContentProps) => {
  const symbolContent = symbol ? <Block marginLeft="4px">{symbol}</Block> : null

  if (!number) {
    return null
  }

  if (prevNumber) {
    return (
      <Flex className="items-center" translate="no">
        {textSize === "small" ? (
          <NumberTicker.Body
            end={number}
            size="medium"
            start={prevNumber}
            weight="semibold"
          />
        ) : (
          <NumberTicker.Heading end={number} size="small" start={prevNumber} />
        )}
        {symbolContent}
      </Flex>
    )
  }
  return (
    <Flex className="items-center" translate="no">
      {number}
      {symbolContent}
    </Flex>
  )
}

export function DetailColumn({
  prevNumber,
  number,
  label,
  symbol,
  tooltip,
  url,
  onClick,
  testId,
  variant = "default",
  labelClassName,
  numberClassName,
}: DetailItem & {
  labelClassName?: ClassValue
  numberClassName?: ClassValue
}) {
  const numberDefaultClassName =
    "styledPhoenixText text-primary hover:text-secondary active:text-secondary"

  const isMerchandising = useIsMerchandisingViewEnabled()

  const isLessThanXXl = useIsLessThanXXl()

  if (variant === "inline") {
    return (
      <Flex>
        <WrappedLink label={label} testId={testId} url={url} onClick={onClick}>
          <FlexColumn className="flex-row items-center gap-2">
            <>
              <Text.Body
                className={classNames(labelClassName)}
                color="secondary"
                size="small"
              >
                {label}
              </Text.Body>
              <Text.Body
                className={classNames(numberDefaultClassName, numberClassName)}
                size="medium"
                weight="semibold"
              >
                <NumberContent
                  number={number}
                  prevNumber={prevNumber}
                  symbol={symbol}
                  textSize="small"
                />
              </Text.Body>
            </>
          </FlexColumn>
        </WrappedLink>
      </Flex>
    )
  }

  if (isMerchandising) {
    const headerContent = (
      <NumberContent
        number={number}
        prevNumber={prevNumber}
        symbol={symbol}
        textSize={isLessThanXXl ? "small" : "large"}
      />
    )
    return (
      <WrappedLink label={label} testId={testId} url={url} onClick={onClick}>
        <FlexColumn className="items-start">
          <Tooltip content={tooltip} disabled={!tooltip}>
            {isLessThanXXl ? (
              <Text.Body
                className={classNames(numberDefaultClassName, numberClassName)}
                responsive
              >
                {headerContent}
              </Text.Body>
            ) : (
              <Text.Heading
                className={classNames(numberDefaultClassName, numberClassName)}
                size="small"
              >
                {headerContent}
              </Text.Heading>
            )}
          </Tooltip>
          <Text.Body
            className={classNames(
              "whitespace-nowrap text-secondary",
              labelClassName,
            )}
            responsive
          >
            {label}
          </Text.Body>
        </FlexColumn>
      </WrappedLink>
    )
  }

  return (
    <>
      <Media lessThan="sm">
        <WrappedLink label={label} testId={testId} url={url} onClick={onClick}>
          <FlexColumn className="items-start">
            <Tooltip content={tooltip} disabled={!tooltip}>
              <Text.Body
                className={classNames(numberDefaultClassName, numberClassName)}
                size="medium"
                weight="semibold"
              >
                <NumberContent
                  number={number}
                  prevNumber={prevNumber}
                  symbol={symbol}
                  textSize="small"
                />
              </Text.Body>
            </Tooltip>
            <Text.Body
              className={classNames(labelClassName)}
              color="secondary"
              size="tiny"
            >
              {label}
            </Text.Body>
          </FlexColumn>
        </WrappedLink>
      </Media>
      <Media between={["sm", "md"]}>
        <WrappedLink label={label} testId={testId} url={url} onClick={onClick}>
          <FlexColumn className="items-start">
            <Tooltip content={tooltip} disabled={!tooltip}>
              <Text.Heading
                className={classNames(numberDefaultClassName, numberClassName)}
                size="small"
              >
                <NumberContent
                  number={number}
                  prevNumber={prevNumber}
                  symbol={symbol}
                  textSize="large"
                />
              </Text.Heading>
            </Tooltip>
            <Text.Body
              className={classNames(labelClassName)}
              color="secondary"
              size="small"
            >
              {label}
            </Text.Body>
          </FlexColumn>
        </WrappedLink>
      </Media>
      <Media greaterThanOrEqual="md">
        <WrappedLink label={label} testId={testId} url={url} onClick={onClick}>
          <FlexColumn className="items-start">
            <Tooltip content={tooltip} disabled={!tooltip}>
              <Text.Heading
                className={classNames(numberDefaultClassName, numberClassName)}
                size="small"
              >
                <NumberContent
                  number={number}
                  prevNumber={prevNumber}
                  symbol={symbol}
                  textSize="large"
                />
              </Text.Heading>
            </Tooltip>
            <Text.Body
              className={classNames(labelClassName)}
              color="secondary"
              size="small"
            >
              {label}
            </Text.Body>
          </FlexColumn>
        </WrappedLink>
      </Media>
    </>
  )
}

const Details = ({
  details,
  labelClassName,
  numberClassName,
}: Pick<
  AccountOrCollectionInfoProps,
  "details" | "labelClassName" | "numberClassName"
>) => {
  const merchandisingEnabled = useIsMerchandisingViewEnabled()

  if (!details) {
    return null
  }

  // On mobile if we have >= 5 stats show 3 columns
  // otherwise default to 2 columns.
  const overflow = 5
  const max = details.length >= overflow ? 3 : 2

  const classContainer = classNames(
    "mt-6 justify-between space-x-8 overflow-y-hidden sm:justify-start sm:space-x-10",
    merchandisingEnabled
      ? "mt-0 overflow-x-scroll lg:overflow-x-auto"
      : "overflow-x-auto",
  )

  const oneRowDetails = (
    <Flex className={classContainer}>
      {details.map((detail, idx) => (
        <DetailColumn
          {...detail}
          key={idx}
          labelClassName={labelClassName}
          numberClassName={numberClassName}
        />
      ))}
    </Flex>
  )

  return (
    <ErrorBoundary>
      {merchandisingEnabled ? (
        oneRowDetails
      ) : (
        <>
          <Media greaterThanOrEqual="md">{oneRowDetails}</Media>
          <Media lessThan="md">
            <Flex className={classContainer}>
              <DetailsColumnContainer>
                {getEls(details, max, 0).map((detail, idx) => (
                  <DetailColumn
                    {...detail}
                    key={idx}
                    labelClassName={labelClassName}
                    numberClassName={numberClassName}
                  />
                ))}
              </DetailsColumnContainer>
              <DetailsColumnContainer>
                {getEls(details, max, 1).map((detail, idx) => (
                  <DetailColumn
                    {...detail}
                    key={idx}
                    labelClassName={labelClassName}
                    numberClassName={numberClassName}
                  />
                ))}
              </DetailsColumnContainer>
              {details.length >= overflow && (
                <DetailsColumnContainer>
                  {getEls(details, max, 2).map((detail, idx) => (
                    <DetailColumn
                      {...detail}
                      key={idx}
                      labelClassName={labelClassName}
                      numberClassName={numberClassName}
                    />
                  ))}
                </DetailsColumnContainer>
              )}
            </Flex>
          </Media>
        </>
      )}
    </ErrorBoundary>
  )
}

const DetailsColumnContainer = styled(Flex)`
  gap: 12px;
  flex-direction: column;
  max-width: calc(100% / 3);
`

const MainText = ({
  text,
  mobileLines = 1,
  desktopLines = 1,
}: Pick<
  AccountOrCollectionInfoProps,
  "text" | "mobileLines" | "desktopLines"
>) => {
  return (
    <>
      <Media greaterThanOrEqual="sm">
        <TextContainer lines={desktopLines} text={text} />
      </Media>
      <Media lessThan="sm">
        <TextContainer lines={mobileLines} text={text} />
      </Media>
    </>
  )
}

type TextOverflowProps = {
  children: React.ReactNode
  lines: number
}

const TextOverflowComponent = ({ lines, children }: TextOverflowProps) => (
  <TextOverflow lines={lines}>
    <Media greaterThanOrEqual="md">
      <Text.Body size="medium">{children}</Text.Body>
    </Media>
    <Media lessThan="md">
      <Text.Body size="small">{children}</Text.Body>
    </Media>
  </TextOverflow>
)

const TextContainer = ({
  lines,
  text,
}: Omit<TextOverflowProps, "children"> &
  Pick<AccountOrCollectionInfoProps, "text">) => {
  if (!text) {
    return null
  }
  return (
    <TextOverflowComponent lines={lines}>
      {typeof text === "string" ? <InfoMarkdown small text={text} /> : text}
    </TextOverflowComponent>
  )
}

export const InfoMarkdown = ({
  text,
  small,
}: {
  text: string
  small?: boolean
}) => {
  const headerOverride:
    | keyof JSX.IntrinsicElements
    | HeadingComponent
    | undefined = ({ children }) => (
    <Text
      asChild
      className="block text-primary"
      size={small ? "small" : "medium"}
      weight={small ? "regular" : "semibold"}
    >
      <div>{children}</div>
    </Text>
  )

  return (
    <Markdown
      components={{
        h1: headerOverride,
        h2: headerOverride,
        h3: headerOverride,
        h4: headerOverride,
        h5: headerOverride,
        h6: headerOverride,
      }}
      supportLineClamp
    >
      {text}
    </Markdown>
  )
}

const TextOverflow = ({ children, lines }: TextOverflowProps) => {
  const t = useTranslate("phoenix")
  const containerRef = useRef<HTMLDivElement>(null)
  const [seeMore, setSeeMore] = useState(false)
  const [overflowed, setOverflowed] = useState(false)

  useLayoutEffect(() => {
    if (containerRef.current) {
      setOverflowed(
        containerRef.current.scrollHeight > containerRef.current.clientHeight,
      )
    }
  }, [])

  return (
    <Flex
      className={classNames(
        "max-w-full break-words sm:max-w-[80%] lg:max-w-[60%] xl:max-w-4xl",
        seeMore ? "flex-col" : "flex-row",
      )}
    >
      <OverflowContainer
        $lines={seeMore ? undefined : lines}
        asChild
        className={seeMore ? undefined : "whitespace-nowrap"}
        ref={containerRef}
        size="small"
      >
        <div>{children}</div>
      </OverflowContainer>
      {overflowed && (
        <StyledContainer alignSelf="flex-start">
          <UnstyledButton onClick={() => setSeeMore(!seeMore)}>
            <Block className="whitespace-nowrap">
              <Media lessThan="md">
                <Text.Body
                  asChild
                  className={classNames("styledPhoenixText", seeMore && "pt-2")}
                  size="small"
                  weight="semibold"
                >
                  <div>
                    {seeMore
                      ? t("info.seeLess", "See less")
                      : t("info.seeMore", "See more")}
                  </div>
                </Text.Body>
              </Media>
              <Media greaterThanOrEqual="md">
                <Text.Body
                  asChild
                  className={classNames("styledPhoenixText", seeMore && "pt-2")}
                  size="medium"
                  weight="semibold"
                >
                  <div>
                    {seeMore
                      ? t("info.seeLess", "See less")
                      : t("info.seeMore", "See more")}
                  </div>
                </Text.Body>
              </Media>
            </Block>
          </UnstyledButton>
        </StyledContainer>
      )}
    </Flex>
  )
}

const StyledContainer = styled(Block)`
  .styledPhoenixText {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            color: `${props.theme.colors.fog}`,
          },
          light: {
            color: props.theme.colors.oil,
          },
        },
      })}
  }

  ${props =>
    themeVariant({
      variants: {
        dark: {
          color: `${props.theme.colors.fog}`,
        },
        light: {
          color: props.theme.colors.oil,
        },
      },
    })}

  &:hover,
  :hover .styledPhoenixText {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            color: rgba(props.theme.colors.fog, 0.8),
          },
          light: {
            color: rgba(props.theme.colors.oil, 0.8),
          },
        },
      })}
  }
  &:active,
  :active .styledPhoenixText {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            color: rgba(props.theme.colors.fog, 0.6),
          },
          light: {
            color: rgba(props.theme.colors.oil, 0.6),
          },
        },
      })}
  }
`

const OverflowContainer = styled(Text.Body)<{
  $lines?: number
  $variant?: string
}>`
  display: block;
  width: 100%;

  li::after {
    content: " \\A";
  }

  ${props =>
    props.$variant === "caption" &&
    css`
      padding-right: 32px;
    `}

  ${props =>
    props.$lines &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.$lines};
      -webkit-box-orient: vertical;
      white-space: normal;
    `}

  ${props =>
    themeVariant({
      variants: {
        dark: {
          color: `${props.theme.colors.fog}`,
        },
        light: {
          color: props.theme.colors.oil,
        },
      },
    })}
`
