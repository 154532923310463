import { Component } from "react"
import { IS_SERVER } from "@/constants/environment"
import { captureException } from "@/lib/sentry"

/**
 *
 * Whenever a user translates a page using Google Translate/Safari Translate, the
 * translator changes the DOM structure of the page. Normally it only adds <font> tags
 * around the translated text but in some cases it might reorganize the DOM structure
 * all together. This causes React to throw an error because it can't find the
 * component it's looking for. (More Context: https://github.com/facebook/react/issues/11538)
 *
 * This component is a wrapper that can be used in any problematic component to
 * catch the error and prevent the app from crashing. It also re-renders the
 * tree on error to prevent the user from noticing the error.
 */

const matchPatterns = [
  /The node to be removed is not a child of this node/,
  /Failed to execute 'insertBefore' on 'Node':/,
  /Failed to execute 'appendChild' on 'Node':/,
  /Failed to execute 'removeChild' on 'Node':/,
]

export type TranslationErrorBoundaryProps = {
  children: React.ReactNode
}

export class TranslationErrorBoundary extends Component<TranslationErrorBoundaryProps> {
  // if the error matches, re-render the three, otherwise throw the error
  static getDerivedStateFromError(error: Error) {
    if (matchPatterns.some(pattern => pattern.test(error.message))) {
      captureException(error, {
        level: "warning",
        tags: {
          translationError: IS_SERVER ? "server" : navigator.language,
        },
      })
      return {}
    }

    throw error
  }

  render() {
    const { children } = this.props

    return children
  }
}
