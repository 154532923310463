import type { _FragmentRefs } from "relay-runtime"
import { dropTypeTests_stages$data } from "@/lib/graphql/__generated__/dropTypeTests_stages.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

const readStagesWithMintOptions = inlineFragmentize<dropTypeTests_stages$data>(
  graphql`
    fragment dropTypeTests_stages on DropV2Type @inline {
      stages {
        ... on DropStage1155PresaleLinearPricingType {
          mintOptions {
            __typename
          }
        }
        ... on DropStage1155PublicLinearPricingType {
          mintOptions {
            __typename
          }
        }
      }
    }
  `,
  i => i,
)

export const isDrop721 = (dropTypename: string): boolean => {
  return dropTypename.includes("721")
}
export const isDrop1155 = (dropTypename: string): boolean => {
  return dropTypename.includes("1155")
}
export const isDropOpenEdition = (dropTypename: string): boolean => {
  return dropTypename.includes("Open")
}

const getMintOptions = (
  ref: dropTypeTests_stages$data | _FragmentRefs<"dropTypeTests_stages">,
): number => {
  const mintOptionData = readStagesWithMintOptions(ref)
  return mintOptionData.stages.reduce((acc, current) => {
    if (current.mintOptions?.length) {
      return Math.max(acc, current.mintOptions.length)
    }
    return acc
  }, 0)
}

export const hasMintOptions = (
  ref: dropTypeTests_stages$data | _FragmentRefs<"dropTypeTests_stages">,
): boolean => {
  return getMintOptions(ref) >= 1
}
