import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { useIsMerchandisingViewEnabled } from "@/hooks/useFlag"
import { bn } from "@/lib/helpers/numberUtils"
import {
  HEIGHT_OF_DIGIT,
  NUMBER_TICKER_DIGITS,
  DIGIT_CONFIG,
  COLOR_CONFIG,
} from "./constants"
import {
  switchColors,
  switchDigits,
  changeWidth,
  padToMatchLength,
  getPositionsAndWidth,
} from "./utils"

export type TextSize = "large" | "medium" | "small" | "tiny"
export type TextType = "display" | "heading" | "body"
export type TextWeight = "semibold" | "regular"
export type TransitionType = "increasing" | "decreasing"

type DigitContentProps = {
  size: TextSize
  type: TextType
  digit: string
  weight?: TextWeight
  transition: TransitionType
}

const DigitContent = ({
  size,
  type,
  digit,
  weight,
  transition,
}: DigitContentProps) => {
  if (type === "display") {
    return (
      <StyledTextDisplay
        size={size === "tiny" ? "small" : size}
        transition={transition}
      >
        {digit}
      </StyledTextDisplay>
    )
  }
  if (type === "heading") {
    return (
      <StyledTextHeading
        size={size === "tiny" ? "small" : size}
        transition={transition}
      >
        {digit}
      </StyledTextHeading>
    )
  }
  return (
    <StyledTextBody
      size={size === "large" ? "medium" : size}
      transition={transition}
      weight={weight}
    >
      {digit}
    </StyledTextBody>
  )
}

type Props = {
  start: string
  end: string
  size: TextSize
  weight?: TextWeight
  type: TextType
}

const NumberTickerBase = ({
  start: s,
  end: e,
  size,
  weight = "regular",
  type,
}: Props) => {
  const { start, end } = padToMatchLength(s, e)
  const {
    xPositions: xPositionsStart,
    yPositions: yPositionsStart,
    width: startWidth,
  } = getPositionsAndWidth(start, type, size, weight)
  const {
    xPositions: xPositionsEnd,
    yPositions: yPositionsEnd,
    width: endWidth,
  } = getPositionsAndWidth(end, type, size, weight)

  const startBn = bn(s)
  const endBn = bn(e)
  const transitionType = startBn.isGreaterThan(endBn)
    ? "decreasing"
    : endBn.isGreaterThan(startBn)
    ? "increasing"
    : "none"

  const isMerchandisingEnabled = useIsMerchandisingViewEnabled()

  const unsupportedCharacter =
    // NOTE(@laurafiuza): I think this is an eslint bug - it's technically correct,
    // but when I try removing the unnecessary conditions (last two), another Typescript
    // error appears in the <Digit> props because they "could possibly be null", which
    // is not true. Disabling for now.

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !yPositionsStart || !yPositionsEnd || !xPositionsStart || !xPositionsEnd

  if (unsupportedCharacter || transitionType === "none") {
    // Don't animate if the numbers are equal,
    // or whether there is an unsupported character

    if (type === "display") {
      return (
        <Text.Display size={size === "tiny" ? "small" : size}>{e}</Text.Display>
      )
    }
    if (type === "heading") {
      return (
        <Text.Heading size={size === "tiny" ? "small" : size}>{e}</Text.Heading>
      )
    }
    return (
      <Text.Body
        responsive={isMerchandisingEnabled}
        size={size === "large" ? "medium" : size}
        weight={weight}
      >
        {e}
      </Text.Body>
    )
  }

  return (
    <DigitContainer
      aria-label={end}
      endWidth={`${endWidth}px`}
      size={size}
      startWidth={`${startWidth}px`}
      type={type}
    >
      <Flex>
        {yPositionsStart.map((_, i) => {
          return (
            <Digit
              key={i}
              xPositionEnd={`-${xPositionsEnd[i]}px`}
              xPositionStart={`-${xPositionsStart[i]}px`}
              yPositionEnd={`-${yPositionsEnd[i]}px`}
              yPositionStart={`-${yPositionsStart[i]}px`}
            >
              {NUMBER_TICKER_DIGITS.map(digit => (
                <div key={digit}>
                  <DigitContent
                    digit={digit}
                    size={size}
                    transition={transitionType}
                    type={type}
                    weight={weight}
                  />
                </div>
              ))}
            </Digit>
          )
        })}
      </Flex>
    </DigitContainer>
  )
}

const SwitchColorsAnimationIncreasing = css`
  animation: ${props => switchColors(props.theme.colors.greenBase)}
    ${COLOR_CONFIG};
`

const SwitchColorsAnimationDecreasing = css`
  animation: ${props => switchColors(props.theme.colors.redBase)}
    ${COLOR_CONFIG};
`

const StyledTextBody = styled(Text.Body)<{ transition: TransitionType }>`
  ${props =>
    props.transition === "increasing"
      ? SwitchColorsAnimationIncreasing
      : SwitchColorsAnimationDecreasing}
`

const StyledTextHeading = styled(Text.Heading)<{ transition: TransitionType }>`
  ${props =>
    props.transition === "increasing"
      ? SwitchColorsAnimationIncreasing
      : SwitchColorsAnimationDecreasing}
`

const StyledTextDisplay = styled(Text.Display)<{ transition: TransitionType }>`
  ${props =>
    props.transition === "increasing"
      ? SwitchColorsAnimationIncreasing
      : SwitchColorsAnimationDecreasing}
`

const DigitContainer = styled.div<{
  endWidth: string
  startWidth: string
  type: TextType
  size: TextSize
}>`
  height: ${props => HEIGHT_OF_DIGIT[props.type][props.size]}px;
  overflow: hidden;
  width: ${props => props.startWidth};
  animation: ${props => changeWidth(props.endWidth)} ${DIGIT_CONFIG};
`

const Digit = styled.div<{
  xPositionStart: string
  yPositionStart: string
  xPositionEnd: string
  yPositionEnd: string
}>`
  transform: translate(
    ${props => props.xPositionStart},
    ${props => props.yPositionStart}
  );
  animation: ${props => switchDigits(props.xPositionEnd, props.yPositionEnd)}
    ${DIGIT_CONFIG};
`

const NumberTickerBody = styled(NumberTickerBase).attrs({ type: "body" })``
const NumberTickerHeading = styled(NumberTickerBase).attrs({
  type: "heading",
})``
const NumberTickerDisplay = styled(NumberTickerBase).attrs({
  type: "display",
})``

export const NumberTicker = Object.assign(NumberTickerBody, {
  Body: NumberTickerBody,
  Heading: NumberTickerHeading,
  Display: NumberTickerDisplay,
})
