export const HEIGHT_OF_DIGIT = {
  display: { large: 56, medium: 40, small: 32, tiny: 32 },
  heading: { large: 40, medium: 32, small: 28, tiny: 28 },
  body: { large: 24, medium: 24, small: 22.5, tiny: 22.5 },
}

export const MAX_WIDTH = {
  display: {
    large: { regular: 32.5, semibold: 32.5 },
    medium: { regular: 21.66, semibold: 21.66 },
    small: { regular: 16.25, semibold: 16.25 },
    tiny: { regular: 16.25, semibold: 16.25 },
  },
  heading: {
    large: { regular: 21.16, semibold: 21.16 },
    medium: { regular: 15.87, semibold: 15.87 },
    small: { regular: 13.23, semibold: 13.23 },
    tiny: { regular: 13.23, semibold: 13.23 },
  },
  body: {
    large: { regular: 10.16, semibold: 10.578 },
    medium: { regular: 10.16, semibold: 10.578 },
    small: { regular: 8.89, semibold: 9.26 },
    tiny: { regular: 7.63, semibold: 7.94 },
  },
}

export const NUMBER_WIDTH_OFFSETS = {
  "0": {
    display: {
      large: { regular: 1.2, semibold: 1.2 },
      medium: { regular: 0.79, semibold: 0.79 },
      small: { regular: 0.6, semibold: 0.6 },
      tiny: { regular: 0.6, semibold: 0.6 },
    },
    heading: {
      large: { regular: 0.48, semibold: 0.48 },
      medium: { regular: 0.36, semibold: 0.36 },
      small: { regular: 0.305, semibold: 0.305 },
      tiny: { regular: 0.305, semibold: 0.305 },
    },
    body: {
      large: { regular: 0.11, semibold: 0.234 },
      medium: { regular: 0.11, semibold: 0.234 },
      small: { regular: 0.09, semibold: 0.21 },
      tiny: { regular: 0.09, semibold: 0.18 },
    },
  },
  "1": {
    display: {
      large: { regular: 14.45, semibold: 14.45 },
      medium: { regular: 9.62, semibold: 9.62 },
      small: { regular: 7.22, semibold: 7.22 },
      tiny: { regular: 7.22, semibold: 7.22 },
    },
    heading: {
      large: { regular: 9.57, semibold: 9.57 },
      medium: { regular: 7.17, semibold: 7.17 },
      small: { regular: 5.985, semibold: 5.985 },
      tiny: { regular: 5.985, semibold: 5.985 },
    },
    body: {
      large: { regular: 5.03, semibold: 4.781 },
      medium: { regular: 5.03, semibold: 4.781 },
      small: { regular: 4.41, semibold: 4.19 },
      tiny: { regular: 3.79, semibold: 3.59 },
    },
  },
  "2": {
    display: {
      large: { regular: 5.09, semibold: 5.09 },
      medium: { regular: 3.39, semibold: 3.39 },
      small: { regular: 2.54, semibold: 2.54 },
      tiny: { regular: 2.54, semibold: 2.54 },
    },
    heading: {
      large: { regular: 2.78, semibold: 2.78 },
      medium: { regular: 2.09, semibold: 2.09 },
      small: { regular: 1.743, semibold: 1.743 },
      tiny: { regular: 1.743, semibold: 1.743 },
    },
    body: {
      large: { regular: 0.96, semibold: 1.39 },
      medium: { regular: 0.96, semibold: 1.39 },
      small: { regular: 0.84, semibold: 1.22 },
      tiny: { regular: 0.72, semibold: 1.05 },
    },
  },

  "3": {
    display: {
      large: { regular: 3.45, semibold: 3.45 },
      medium: { regular: 2.29, semibold: 2.29 },
      small: { regular: 1.73, semibold: 1.73 },
      tiny: { regular: 1.73, semibold: 1.73 },
    },
    heading: {
      large: { regular: 1.99, semibold: 1.99 },
      medium: { regular: 1.49, semibold: 1.49 },
      small: { regular: 1.243, semibold: 1.243 },
      tiny: { regular: 1.243, semibold: 1.243 },
    },
    body: {
      large: { regular: 0.73, semibold: 0.992 },
      medium: { regular: 0.73, semibold: 0.992 },
      small: { regular: 0.64, semibold: 0.87 },
      tiny: { regular: 0.56, semibold: 0.74 },
    },
  },
  "4": {
    display: {
      large: { regular: 0, semibold: 0 },
      medium: { regular: 0, semibold: 0 },
      small: { regular: 0, semibold: 0 },
      tiny: { regular: 0, semibold: 0 },
    },
    heading: {
      large: { regular: 0, semibold: 0 },
      medium: { regular: 0, semibold: 0 },
      small: { regular: 0, semibold: 0 },
      tiny: { regular: 0, semibold: 0 },
    },
    body: {
      large: { regular: 0.09, semibold: 0 },
      medium: { regular: 0.09, semibold: 0 },
      small: { regular: 0.08, semibold: 0 },
      tiny: { regular: 0.08, semibold: 0.08 },
    },
  },
  "5": {
    display: {
      large: { regular: 1.3, semibold: 1.3 },
      medium: { regular: 0.86, semibold: 0.86 },
      small: { regular: 0.65, semibold: 0.65 },
      tiny: { regular: 0.65, semibold: 0.65 },
    },
    heading: {
      large: { regular: 0.55, semibold: 0.55 },
      medium: { regular: 0.41, semibold: 0.41 },
      small: { regular: 0.344, semibold: 0.344 },
      tiny: { regular: 0.344, semibold: 0.344 },
    },
    body: {
      large: { regular: 0.11, semibold: 1.273 },
      medium: { regular: 0.11, semibold: 1.273 },
      small: { regular: 0.09, semibold: 0.24 },
      tiny: { regular: 0.09, semibold: 0.21 },
    },
  },
  "6": {
    display: {
      large: { regular: 1.92, semibold: 1.92 },
      medium: { regular: 1.27, semibold: 1.27 },
      small: { regular: 0.96, semibold: 0.96 },
      tiny: { regular: 0.96, semibold: 0.96 },
    },
    heading: {
      large: { regular: 0.68, semibold: 0.68 },
      medium: { regular: 0.5, semibold: 0.5 },
      small: { regular: 0.422, semibold: 0.422 },
      tiny: { regular: 0.422, semibold: 0.422 },
    },
    body: {
      large: { regular: 0, semibold: 0.336 },
      medium: { regular: 0, semibold: 0.336 },
      small: { regular: 0, semibold: 0.3 },
      tiny: { regular: 0, semibold: 0.25 },
    },
  },
  "7": {
    display: {
      large: { regular: 6.81, semibold: 6.81 },
      medium: { regular: 4.53, semibold: 4.53 },
      small: { regular: 3.41, semibold: 3.41 },
      tiny: { regular: 3.41, semibold: 3.41 },
    },
    heading: {
      large: { regular: 3.62, semibold: 3.62 },
      medium: { regular: 2.71, semibold: 2.71 },
      small: { regular: 2.266, semibold: 2.266 },
      tiny: { regular: 2.266, semibold: 2.266 },
    },
    body: {
      large: { regular: 1.42, semibold: 1.805 },
      medium: { regular: 1.42, semibold: 1.805 },
      small: { regular: 1.24, semibold: 1.58 },
      tiny: { regular: 1.08, semibold: 1.36 },
    },
  },
  "8": {
    display: {
      large: { regular: 1.39, semibold: 1.39 },
      medium: { regular: 0.92, semibold: 0.92 },
      small: { regular: 0.7, semibold: 0.7 },
      tiny: { regular: 0.7, semibold: 0.7 },
    },
    heading: {
      large: { regular: 0.58, semibold: 0.58 },
      medium: { regular: 0.43, semibold: 0.43 },
      small: { regular: 0.36, semibold: 0.36 },
      tiny: { regular: 0.36, semibold: 0.36 },
    },
    body: {
      large: { regular: 0.15, semibold: 0.289 },
      medium: { regular: 0.15, semibold: 0.289 },
      small: { regular: 0.05, semibold: 0.6 },
      tiny: { regular: 0.05, semibold: 0.22 },
    },
  },
  "9": {
    display: {
      large: { regular: 2.98, semibold: 2.98 },
      medium: { regular: 1.97, semibold: 1.97 },
      small: { regular: 1.48, semibold: 1.48 },
      tiny: { regular: 1.48, semibold: 1.48 },
    },
    heading: {
      large: { regular: 1.12, semibold: 1.12 },
      medium: { regular: 0.84, semibold: 0.84 },
      small: { regular: 0.704, semibold: 0.704 },
      tiny: { regular: 0.704, semibold: 0.704 },
    },
    body: {
      large: { regular: 0.07, semibold: 0.555 },
      medium: { regular: 0.07, semibold: 0.555 },
      small: { regular: 0.07, semibold: 0.49 },
      tiny: { regular: 0.07, semibold: 0.42 },
    },
  },
  ".": {
    display: {
      large: { regular: 18.96, semibold: 18.96 },
      medium: { regular: 12.63, semibold: 12.63 },
      small: { regular: 9.48, semibold: 9.48 },
      tiny: { regular: 9.48, semibold: 9.48 },
    },
    heading: {
      large: { regular: 12.84, semibold: 12.84 },
      medium: { regular: 9.63, semibold: 9.63 },
      small: { regular: 8.024, semibold: 8.024 },
      tiny: { regular: 8.024, semibold: 8.024 },
    },
    body: {
      large: { regular: 6.79, semibold: 6.414 },
      medium: { regular: 6.79, semibold: 6.414 },
      small: { regular: 5.94, semibold: 5.62 },
      tiny: { regular: 5.11, semibold: 4.81 },
    },
  },
  " ": {
    display: {
      large: { regular: 32.5, semibold: 32.5 },
      medium: { regular: 21.66, semibold: 21.66 },
      small: { regular: 16.25, semibold: 16.25 },
      tiny: { regular: 16.25, semibold: 16.25 },
    },
    heading: {
      large: { regular: 21.16, semibold: 21.16 },
      medium: { regular: 15.87, semibold: 15.87 },
      small: { regular: 13.23, semibold: 13.23 },
      tiny: { regular: 13.23, semibold: 13.23 },
    },
    body: {
      large: { regular: 10.16, semibold: 10.578 },
      medium: { regular: 10.16, semibold: 10.578 },
      small: { regular: 8.89, semibold: 9.26 },
      tiny: { regular: 7.63, semibold: 7.94 },
    },
  },
}

export const DIGIT_CONFIG = "2s cubic-bezier(0.65, 0.05, 0.36, 1) forwards"
export const COLOR_CONFIG = "4s linear"

export const NUMBER_TICKER_DIGITS = Object.keys(NUMBER_WIDTH_OFFSETS)
