/**
 * @generated SignedSource<<564a26d5e696128f8a82b0c964213b19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DropStageStageType = "PRESALE" | "PUBLIC" | "%future added value";
export type usePolledDropDataQuery$variables = {
  collection?: string | null;
};
export type usePolledDropDataQuery$data = {
  readonly collection: {
    readonly dropv2: {
      readonly stages: ReadonlyArray<{
        readonly availableMintsForUser?: number;
        readonly endTime: string;
        readonly isEligible: boolean;
        readonly label: string;
        readonly perWalletLimit?: number | null;
        readonly relayId: string;
        readonly stageType: DropStageStageType;
        readonly startTime: string;
        readonly " $fragmentSpreads": FragmentRefs<"useDropStages">;
      }>;
    } | null;
  } | null;
};
export type usePolledDropDataQuery = {
  response: usePolledDropDataQuery$data;
  variables: usePolledDropDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEligible",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "availableMintsForUser",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "perWalletLimit",
    "storageKey": null
  }
],
v8 = {
  "kind": "InlineFragment",
  "selections": (v7/*: any*/),
  "type": "DropStage721PresaleLinearPricingType",
  "abstractKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": (v7/*: any*/),
  "type": "DropStage721PublicLinearPricingType",
  "abstractKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stageType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePolledDropDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dropv2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "stages",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "useDropStages",
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePolledDropDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dropv2",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "stages",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isDropStageV2Type"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "645183c0e3ed0331e1c83f1842674baa",
    "id": null,
    "metadata": {},
    "name": "usePolledDropDataQuery",
    "operationKind": "query",
    "text": "query usePolledDropDataQuery(\n  $collection: CollectionSlug\n) {\n  collection(collection: $collection) {\n    dropv2 {\n      __typename\n      stages {\n        __typename\n        ...useDropStages\n        label\n        relayId\n        startTime\n        endTime\n        isEligible\n        ... on DropStage721PresaleLinearPricingType {\n          availableMintsForUser\n          perWalletLimit\n        }\n        ... on DropStage721PublicLinearPricingType {\n          availableMintsForUser\n          perWalletLimit\n        }\n        stageType\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment useDropStages on DropStageV2Type {\n  __isDropStageV2Type: __typename\n  startTime\n  endTime\n}\n"
  }
};
})();

(node as any).hash = "0ffe99e0f9d4141abfaa11051e74a957";

export default node;
