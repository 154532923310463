/**
 * @generated SignedSource<<fa631604a082129f097ed925b52fa329>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type usePrimaryDropPageStatsEventSubscription$variables = {
  slug: string;
};
export type usePrimaryDropPageStatsEventSubscription$data = {
  readonly statsEvent: {
    readonly collection: {
      readonly statsV2: {
        readonly hasFungibles: boolean;
        readonly totalQuantity: string;
        readonly totalSupply: number;
      };
    };
  };
};
export type usePrimaryDropPageStatsEventSubscription = {
  response: usePrimaryDropPageStatsEventSubscription$data;
  variables: usePrimaryDropPageStatsEventSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionStatsV2Type",
  "kind": "LinkedField",
  "name": "statsV2",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFungibles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSupply",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePrimaryDropPageStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StatsEvent",
        "kind": "LinkedField",
        "name": "statsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePrimaryDropPageStatsEventSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StatsEvent",
        "kind": "LinkedField",
        "name": "statsEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f090db2cc23416c2a25c727a7f520d4",
    "id": null,
    "metadata": {},
    "name": "usePrimaryDropPageStatsEventSubscription",
    "operationKind": "subscription",
    "text": "subscription usePrimaryDropPageStatsEventSubscription(\n  $slug: String!\n) {\n  statsEvent(slug: $slug) {\n    collection {\n      statsV2 {\n        hasFungibles\n        totalQuantity\n        totalSupply\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b82fbddd1c7fc3e46de6eb890da4d301";

export default node;
