import React, { createContext, useState } from "react"
import { parseJSON, isAfter, isEqual } from "date-fns"
import { isNil } from "lodash"
import { VariablesOf, useFragment } from "react-relay"
import { CacheConfig, OperationType } from "relay-runtime"
import { usePrimaryDropPageStatsEvent } from "@/features/live-updates/hooks/usePrimaryDropPageStatsEvent"
import { usePolledDropData } from "@/features/primary-drops/hooks/usePolledDropData"
import { PrimaryDropProviderMintProgress_data$key } from "@/lib/graphql/__generated__/PrimaryDropProviderMintProgress_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { isDrop1155 } from "../../utils/dropTypeTests"

type RefetchFn = (
  variables: Partial<VariablesOf<OperationType>>,
  fetchConfig?: CacheConfig,
) => Promise<void>

type PrimaryDropContextProps = {
  collectionSlug: string
  mintedItemCount: number
  totalItemCount?: number
  ctaStageIsActive: boolean
  ctaStageIsPast: boolean
  isOpenEdition: boolean
  isStageActive: (stage: {
    startTime: string
    endTime: string | null
  }) => boolean
  isPastStage: (stage: { endTime: string | null }) => boolean
  refetchCtaStageState: RefetchFn
  mintedOut: boolean
  quantityToMint: number
  setQuantityToMint: (quantity: number) => unknown
  editMode: boolean
}

type ProviderProps = {
  children: React.ReactNode
  collectionSlug: string
  dataKey: PrimaryDropProviderMintProgress_data$key | null
  editMode?: boolean
}

// TODO -- move it to a new place
export const isPastStage = ({ endTime }: { endTime: string | null }) => {
  if (!endTime) {
    return false
  }
  const now = Date.now()
  const end = parseJSON(endTime)

  return isAfter(now, end) || isEqual(now, end)
}

// TODO -- move it to a new place
export const isStageActive = ({
  startTime,
  endTime,
}: {
  startTime: string
  endTime: string | null
}) => {
  const now = Date.now()
  const start = parseJSON(startTime)
  return (
    (isAfter(now, start) || isEqual(start, now)) && !isPastStage({ endTime })
  )
}

const DEFAULT_CONTEXT: PrimaryDropContextProps = {
  collectionSlug: "",
  mintedItemCount: 0,
  totalItemCount: 1,
  ctaStageIsActive: false,
  ctaStageIsPast: false,
  isStageActive,
  isPastStage,
  isOpenEdition: false,
  refetchCtaStageState: async () => await undefined,
  quantityToMint: 1,
  mintedOut: false,
  setQuantityToMint: () => false,
  editMode: false,
}

export const PrimaryDropContext = createContext(DEFAULT_CONTEXT)

export const PrimaryDropProvider = ({
  children,
  collectionSlug,
  dataKey,
  editMode,
}: ProviderProps) => {
  const mintProgressData = useFragment(
    graphql`
      fragment PrimaryDropProviderMintProgress_data on CollectionType {
        relayId
        statsV2 {
          totalSupply
        }
        dropv2 {
          ... on Drop721OpenEditionType {
            mintedItemCount
            __typename
          }
          ... on Drop1155OpenEditionType {
            mintedItemCount
            __typename
          }
          ... on Drop721LimitedEditionType {
            mintedItemCount
            totalItemCount
          }
          ... on Drop1155LimitedEditionType {
            __typename
            mintedItemCount
            totalItemCount
          }
        }
      }
    `,
    dataKey,
  )

  const [quantityToMint, setQuantityToMint] = useState(
    DEFAULT_CONTEXT.quantityToMint,
  )

  const totalItemCount = mintProgressData?.dropv2?.totalItemCount

  // totalSupply decreases when items are burned, so we check it against mintedItemCount on the initial query to determine if the drop is minted out
  const mintCount = Math.max(
    mintProgressData?.dropv2?.mintedItemCount ??
      DEFAULT_CONTEXT.mintedItemCount,
    mintProgressData?.statsV2.totalSupply ?? DEFAULT_CONTEXT.mintedItemCount,
  )
  const mintedOut = mintProgressData?.dropv2?.totalItemCount
    ? mintCount >= mintProgressData.dropv2.totalItemCount
    : false

  usePrimaryDropPageStatsEvent({
    relayId: mintProgressData?.relayId ?? "",
    slug: collectionSlug,
  })

  const is1155 = isDrop1155(mintProgressData?.dropv2?.__typename ?? "")
  const { ctaStageIsActive, ...restCtaStageStatus } = usePolledDropData({
    collectionSlug,
    editMode,
    is1155,
  })

  return (
    <PrimaryDropContext.Provider
      value={{
        collectionSlug,
        mintedItemCount: mintCount,
        totalItemCount,
        ctaStageIsActive,
        quantityToMint,
        isOpenEdition: isNil(mintProgressData?.dropv2?.totalItemCount),
        setQuantityToMint,
        mintedOut,
        editMode: !!editMode,
        ...restCtaStageStatus,
      }}
    >
      {children}
    </PrimaryDropContext.Provider>
  )
}
