import { keyframes } from "styled-components"
import {
  NUMBER_WIDTH_OFFSETS,
  HEIGHT_OF_DIGIT,
  NUMBER_TICKER_DIGITS,
  MAX_WIDTH,
} from "./constants"
import { TextType, TextSize, TextWeight } from "./NumberTicker.react"

export const switchColors = (color: string) => keyframes`
  1% {
    color: ${color};
  }
  90% {
    color: ${color};
  }
`

export const switchDigits = (x: string, y: string) => keyframes`
  to {
    transform: translate(${x}, ${y});
  }
`

export const changeWidth = (width: string) => keyframes`
  to {
    width: ${width};
  }
`

export const padToMatchLength = (s: string, e: string) => {
  let start = s
  let end = e
  for (let i = 0; i < s.length - e.length; i++) {
    end += " "
  }
  for (let i = 0; i < e.length - s.length; i++) {
    start += " "
  }
  return { start, end }
}

export const getPositionsAndWidth = (
  str: string,
  type: TextType,
  size: TextSize,
  weight: TextWeight,
) => {
  let xPositions: number[] = [0]
  let yPositions: number[] = []
  let width = 0
  for (let i = 0; i < str.length; i++) {
    if (!(str[i] in NUMBER_WIDTH_OFFSETS)) {
      // Unsupported character found
      return { xPositions: null, yPositions: null, width: -1 }
    }

    const numberInfo =
      NUMBER_WIDTH_OFFSETS[str[i] as keyof typeof NUMBER_WIDTH_OFFSETS]
    const numberWidthOffset = numberInfo[type][size][weight]

    // Get y position
    yPositions = [
      ...yPositions,
      HEIGHT_OF_DIGIT[type][size] * NUMBER_TICKER_DIGITS.indexOf(str[i]),
    ]

    // Get x position
    if (i < str.length - 1) {
      xPositions = [...xPositions, numberWidthOffset + xPositions[i]]
    }

    // Add to width of string
    width += MAX_WIDTH[type][size][weight] - numberWidthOffset
  }
  return { xPositions, yPositions, width }
}
