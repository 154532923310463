import { getTrackingFn } from "../utils"

export const trackAddToWatchlist = getTrackingFn<{
  collectionSlug: string
  isStatsPage?: boolean
}>("add to watchlist")

export const trackRemoveFromWatchlist = getTrackingFn<{
  collectionSlug: string
  isStatsPage?: boolean
}>("remove from watchlist")
