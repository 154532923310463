import React, { ComponentProps } from "react"
import { Link, Text, classNames } from "@opensea/ui-kit"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import styled, { css } from "styled-components"
import { TranslationErrorBoundary } from "@/design-system/TranslationErrorBoundary"

type Components = NonNullable<ComponentProps<typeof Markdown>["components"]>

export type MarkdownProps = {
  children: string
  className?: string
  supportLineClamp?: boolean
  responsive?: boolean
} & Pick<ComponentProps<typeof ReactMarkdown>, "components">

const MB_CLASS_NAME = "mb-1.5 lg:mb-3 last:mb-0"
export const MARKDOWN_LINE_CLASS = "markdown-line"

export const Markdown = ({
  children,
  className,
  supportLineClamp,
  components,
  responsive,
}: MarkdownProps) => {
  const sharedClassNames = classNames("w-fit", MARKDOWN_LINE_CLASS)
  const baseComponents: Components = {
    a: ({ children, href }) => (
      <Link
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        variant="default"
      >
        {children}
      </Link>
    ),
    img: () => null,
    iframe: () => null,
    p: ({ className, ...props }) => (
      <Text.Body asChild responsive={responsive} size="medium">
        <p
          className={classNames(
            className,
            MB_CLASS_NAME,
            "whitespace-pre-wrap",
            sharedClassNames,
          )}
          {...props}
        />
      </Text.Body>
    ),
    span: ({ className, ...props }) => (
      <Text.Body asChild responsive={responsive} size="medium">
        <span
          className={classNames(className, "whitespace-pre-wrap")}
          {...props}
        />
      </Text.Body>
    ),
    li: ({ className, ...props }) => (
      <li className="list-item list-disc">
        <Text.Body asChild responsive={responsive} size="medium">
          <span
            className={classNames(
              className,
              "whitespace-pre-wrap",
              sharedClassNames,
            )}
            {...props}
          />
        </Text.Body>
      </li>
    ),
    ul: ({ className, ...props }) => (
      <ul
        className={classNames(
          className,
          MB_CLASS_NAME,
          "inline-block list-outside list-disc pl-4",
          sharedClassNames,
        )}
        {...props}
      />
    ),
    ol: ({ className, ...props }) => (
      <ol
        className={classNames(
          className,
          MB_CLASS_NAME,
          "inline-block list-outside list-disc pl-4",
          sharedClassNames,
        )}
        {...props}
      />
    ),
  }
  ;(
    ["h1", "h2", "h3", "h4", "h5", "h6"] as (
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
    )[]
  ).forEach(tag => {
    baseComponents[tag] = ({ children }) => (
      <Text.Heading
        asChild
        className={classNames("relative mb-4", sharedClassNames)}
        responsive={responsive}
        size="medium"
      >
        <h2>{children}</h2>
      </Text.Heading>
    )
  })
  return (
    <div className="[&>*:mt-0]">
      <TranslationErrorBoundary>
        <StyledReactMarkdown
          className={className}
          components={{ ...baseComponents, ...components }}
          remarkPlugins={[remarkGfm]}
          skipHtml
          supportLineClamp={supportLineClamp}
        >
          {children}
        </StyledReactMarkdown>
      </TranslationErrorBoundary>
    </div>
  )
}

const StyledReactMarkdown = styled(ReactMarkdown)<{
  supportLineClamp?: boolean
}>`
  strong {
    font-weight: 600;
  }
  ${({ supportLineClamp }) =>
    supportLineClamp &&
    css`
      p,
      a,
      ul,
      li {
        display: inline;
        margin-top: 0px;
      }
      p:not(:last-child)::after,
      ul:not(:last-child)::after,
      li:not(:last-child)::after {
        content: " \\A\\A";
        white-space: pre;
      }
    `}
`
