/**
 * @generated SignedSource<<59ae90050ca45eb63e750d045260be4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrimaryDropProviderMintProgress_data$data = {
  readonly dropv2: {
    readonly __typename: "Drop721OpenEditionType";
    readonly mintedItemCount?: number;
    readonly totalItemCount?: number;
  } | null;
  readonly relayId: string;
  readonly statsV2: {
    readonly totalSupply: number;
  };
  readonly " $fragmentType": "PrimaryDropProviderMintProgress_data";
};
export type PrimaryDropProviderMintProgress_data$key = {
  readonly " $data"?: PrimaryDropProviderMintProgress_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropProviderMintProgress_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mintedItemCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalItemCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrimaryDropProviderMintProgress_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSupply",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Drop721OpenEditionType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Drop1155OpenEditionType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "type": "Drop721LimitedEditionType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "type": "Drop1155LimitedEditionType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "c8fa2d90a571813a81231e7a910f2ae5";

export default node;
