import React from "react"

type Props = {
  className?: string
  height?: number
  width?: number
  color?: string
  active?: boolean
}

export const StarIcon = ({
  color,
  className,
  width,
  height,
  active,
}: Props) => (
  <svg
    className={className}
    color={color}
    style={{ height, width }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {active ? (
      <>
        <path
          d="M12.6317 3.9063L14.8341 8.33148C14.9415 8.54756 15.15 8.69669 15.3893 8.73017L20.3125 9.43929C20.9168 9.52755 21.1591 10.2641 20.7204 10.6871L17.1592 14.1323C16.9844 14.2997 16.9077 14.5432 16.9475 14.7805L17.788 19.644C17.8923 20.2405 17.2604 20.697 16.7206 20.414L12.3188 18.1162C12.1041 18.0036 11.8465 18.0036 11.6348 18.1162L7.23309 20.414C6.69323 20.697 6.06134 20.2405 6.16563 19.644L7.0061 14.7805C7.04598 14.5432 6.96929 14.3027 6.79445 14.1323L3.224 10.6871C2.78536 10.2641 3.02768 9.52755 3.63196 9.43929L8.55514 8.73017C8.7944 8.69669 9.00298 8.54756 9.11034 8.33148L11.3097 3.9063C11.5796 3.36457 12.3618 3.36457 12.6317 3.9063Z"
          fill="currentColor"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </>
    ) : (
      <path
        d="M12.6,3.9l2.2,4.4c0.1,0.2,0.3,0.4,0.6,0.4l4.9,0.7c0.6,0.1,0.8,0.8,0.4,1.2l-3.6,3.4c-0.2,0.2-0.3,0.4-0.2,0.6
	l0.8,4.9c0.1,0.6-0.5,1.1-1.1,0.8l-4.4-2.3c-0.2-0.1-0.5-0.1-0.7,0l-4.4,2.3c-0.5,0.3-1.2-0.2-1.1-0.8L7,14.8c0-0.2,0-0.5-0.2-0.6
	l-3.6-3.4C2.8,10.3,3,9.5,3.6,9.4l4.9-0.7c0.2,0,0.4-0.2,0.6-0.4l2.2-4.4C11.6,3.4,12.4,3.4,12.6,3.9z"
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="2.5"
      />
    )}
  </svg>
)
