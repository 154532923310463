/**
 * @generated SignedSource<<405ad9363b4fce0a33addd5418e121ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type usePolledDropDataProgressQuery$variables = {
  collection?: string | null;
};
export type usePolledDropDataProgressQuery$data = {
  readonly collection: {
    readonly dropv2: {
      readonly mintedItemCount?: number;
      readonly stages: ReadonlyArray<{
        readonly mintOptions?: ReadonlyArray<{
          readonly mintedItemCount: number;
        }>;
      }>;
    } | null;
  } | null;
};
export type usePolledDropDataProgressQuery = {
  response: usePolledDropDataProgressQuery$data;
  variables: usePolledDropDataProgressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mintedItemCount",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Drop1155LimitedEditionType",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "Drop1155OpenEditionType",
  "abstractKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "mintOptions",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "mintOptions",
    "plural": true,
    "selections": [
      (v7/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePolledDropDataProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dropv2",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "stages",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v6/*: any*/),
                    "type": "DropStage1155PublicLinearPricingType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v6/*: any*/),
                    "type": "DropStage1155PresaleLinearPricingType",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePolledDropDataProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dropv2",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "stages",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "DropStage1155PublicLinearPricingType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "DropStage1155PresaleLinearPricingType",
                    "abstractKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "509c35c176d66404273e8fc572e71112",
    "id": null,
    "metadata": {},
    "name": "usePolledDropDataProgressQuery",
    "operationKind": "query",
    "text": "query usePolledDropDataProgressQuery(\n  $collection: CollectionSlug\n) {\n  collection(collection: $collection) {\n    dropv2 {\n      __typename\n      ... on Drop1155LimitedEditionType {\n        mintedItemCount\n      }\n      ... on Drop1155OpenEditionType {\n        mintedItemCount\n      }\n      stages {\n        __typename\n        ... on DropStage1155PublicLinearPricingType {\n          mintOptions {\n            __typename\n            mintedItemCount\n          }\n        }\n        ... on DropStage1155PresaleLinearPricingType {\n          mintOptions {\n            __typename\n            mintedItemCount\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c54b432888cb5b48f37d3591b38e397";

export default node;
