import { parseJSON, isAfter } from "date-fns"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useDropState_data$key } from "@/lib/graphql/__generated__/useDropState_data.graphql"
import { isStageActive } from "../containers/PrimaryDropProvider"

export type DROP_STATES_TYPE = "ACTIVE" | "UPCOMING" | "ENDED" | "MINTED_OUT"

export const DROP_STATES: Record<DROP_STATES_TYPE, DROP_STATES_TYPE> = {
  ACTIVE: "ACTIVE",
  UPCOMING: "UPCOMING",
  ENDED: "ENDED",
  MINTED_OUT: "MINTED_OUT",
}

type Stage = {
  startTime: string
  endTime: string | null
}

type getDropStateProps = {
  mintedItemCount: number
  totalItemCount?: number
  stages: Stage[]
}

export const getDropState = (data: getDropStateProps): DROP_STATES_TYPE => {
  const { mintedItemCount, totalItemCount, stages } = data
  const mintedOut = totalItemCount ? mintedItemCount >= totalItemCount : false

  const firstStage = stages[0]
  const publicStage = stages[stages.length - 1]

  const now = new Date()

  const publicStageFinished =
    publicStage.endTime && isAfter(now, parseJSON(publicStage.endTime))
  const dropAlreadyStarted = isStageActive({
    startTime: firstStage.startTime,
    endTime: null,
  })

  if (mintedOut) {
    return DROP_STATES.MINTED_OUT
  } else if (publicStageFinished) {
    return DROP_STATES.ENDED
  } else if (dropAlreadyStarted) {
    return DROP_STATES.ACTIVE
  } else {
    return DROP_STATES.UPCOMING
  }
}

export const isDropStateEnded = (dropState: DROP_STATES_TYPE | null) => {
  return dropState === DROP_STATES.ENDED || dropState === DROP_STATES.MINTED_OUT
}

export const useDropState = (
  dropKey: useDropState_data$key | null,
): DROP_STATES_TYPE | null => {
  const data = useFragment<useDropState_data$key>(
    graphql`
      fragment useDropState_data on CollectionType {
        statsV2 {
          totalSupply
        }
        dropv2 {
          stages {
            startTime
            endTime
          }
          mintedItemCount
          ... on Drop721LimitedEditionType {
            totalItemCount
          }
          ... on Drop1155LimitedEditionType {
            totalItemCount
          }
        }
      }
    `,
    dropKey,
  )

  const drop = data?.dropv2
  if (!drop) {
    return null
  }

  return getDropState({
    stages: drop.stages.map(stage => ({
      startTime: stage.startTime,
      endTime: stage.endTime,
    })),
    // totalSupply decreases when items are burned, so we check it against mintedItemCount on the initial query to determine if the drop is minted out
    mintedItemCount: Math.max(
      data.statsV2.totalSupply,
      data.dropv2.mintedItemCount,
    ),
    totalItemCount: drop.totalItemCount,
  })
}
